// Generated by Framer (a96673f)

import { addFonts, cx, CycleVariantState, SVG, useConstant, useIsOnFramerCanvas, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./SdInDMJuu-0.js";

const cycleOrder = ["Qddf5OUJ0"];

const serializationHash = "framer-phscF"

const variantClassNames = {Qddf5OUJ0: "framer-v-1378vtm"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const valuesByLocaleId = {eWWczOETO: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Qddf5OUJ0", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const isOnCanvas = useIsOnFramerCanvas()

const initialVariant = useConstant(() => variant)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1378vtm", className, classNames)} data-framer-name={"Variant 1"} initial={isOnCanvas ? variant : initialVariant } layoutDependency={layoutDependency} layoutId={"Qddf5OUJ0"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-tve27b"} layout={"position"} layoutDependency={layoutDependency} layoutId={"fL97vCSIJ"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 172 122\"></svg>"} svgContentId={3198357186} withExternalLayout/><SVG className={"framer-wl7vfm"} data-framer-name={"Path_4"} fill={"black"} intrinsicHeight={142} intrinsicWidth={351} layoutDependency={layoutDependency} layoutId={"K323Bk1zX"} svg={"<svg width=\"351\" height=\"142\" xmlns=\"http://www.w3.org/2000/svg\"><path stroke=\"#FFF\" stroke-width=\"10\" d=\"m5 105 50-82 89 114L205 5l87 100 54-94\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/></svg>"} withExternalLayout/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-phscF[data-border=\"true\"]::after, .framer-phscF [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-phscF.framer-b3vqny, .framer-phscF .framer-b3vqny { display: block; }", ".framer-phscF.framer-1378vtm { height: 142px; overflow: hidden; position: relative; width: 351px; }", ".framer-phscF .framer-tve27b { flex: none; height: 122px; left: calc(49.71098265895956% - 172px / 2); position: absolute; top: calc(50.00000000000002% - 122px / 2); width: 172px; }", ".framer-phscF .framer-wl7vfm { aspect-ratio: 2.471830985915493 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 142px); left: 0px; position: absolute; top: 0px; width: 351px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 142
 * @framerIntrinsicWidth 351
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerSdInDMJuu: React.ComponentType<Props> = withCSS(Component, css, "framer-phscF") as typeof Component;
export default FramerSdInDMJuu;

FramerSdInDMJuu.displayName = "test";

FramerSdInDMJuu.defaultProps = {height: 142, width: 351};

addFonts(FramerSdInDMJuu, [])